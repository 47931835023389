import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  :global(.color-purple) {
    :global(.icon-flight_svg__shade) {
      fill: ${COLORS.purple200};
      opacity: 1;
    }
  }
  :global(.color-blue) {
    :global(.icon-flight_svg__shade) {
      fill: ${COLORS.blue200};
      opacity: 1;
    }
  }
  :global(.icon-flight.color-gray) {
    :global(path) {
      fill: ${COLORS.black600};
    }
  }
  :global(.icon-flight.color-blue) {
    :global(path) {
      fill: ${COLORS.primary};
    }
  }
  :global(.color-white) {
    :global(path) {
      fill: ${COLORS.white};
    }
  }
  :global(.color-black) {
    :global(path) {
      fill: ${COLORS.black};
    }
  }
  :global(.no-fill) {
    :global(.icon-flight_svg__shade) {
      opacity: 0;
    }
  }
`;

export default styles;
