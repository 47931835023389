import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .campaign-countdown {
    z-index: 100;
    height: 20px;
    width: 100%;
    background: ${COLORS.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    .time-unit-cont {
      margin-left: 4px;
      .time-count {
        margin-right: 4px;
      }
    }
  }
`;

export default styles;
