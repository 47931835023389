import { ReactNode } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import styles from './css';

type Props = {
  children: ReactNode;
};

const LoadingOverlay = ({ children }: Props) => {
  const { isLoading } = useAppSelector((state) => ({
    isLoading: state.loader,
  }));
  const loader = (
    <div className="loader">
      <span className="loader-content">
        <div className="loader-spinner">
          <div />
          <div />
          <div />
          <div />
        </div>
      </span>
      <style jsx>{styles}</style>
    </div>
  );

  return (
    <div>
      {children}
      {isLoading ? loader : null}
    </div>
  );
};

export default LoadingOverlay;
